.stg-homeinfo {
  display: flex;
  padding: 6.4rem 0 !important;
  justify-content: space-around;
  align-items: center;

  color: $primary-700;

  &-link {
    font-size: 20px;
    color: $primary-200;
    font-weight: 600;
  }

  &-texts {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-title {
    &-info {
      position: relative;
      font: normal normal normal 40px/47px DM Serif Display;
      color: $primary-100;
      width: 75%;
      &::after {
        content: '';
        background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
        width: 47px;
        height: 47px;
        position: absolute;
        background-repeat: no-repeat;
        right: -15px;
        bottom: -10px;
        transform: rotate(90deg);
      }
      &::before {
        content: '';
        background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
        width: 47px;
        height: 47px;
        position: absolute;
        background-repeat: no-repeat;
        left: -15px;
        top: -10px;
        transform: rotate(90deg);
      }
    }
  }

  &-description {
    max-width: 70%;

    p {
      font: normal normal 300 20px/24px Montserrat;
      letter-spacing: 0px;
      color: $primary-200;
    }
  }

  &-image {
    position: relative;
    padding: 2rem 1rem !important;
    &::after {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
      width: 70px;
      height: 70px;
      position: absolute;
      background-repeat: no-repeat;
      right: -20px;
      top: 15px;
    }
    &::before {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
      width: 70px;
      height: 70px;
      position: absolute;
      background-repeat: no-repeat;
      left: 0;
      bottom: -10px;
    }
    &-before {
    }
  }
  &-dots {
    position: relative;
    bottom: 30px;
  }
}

.stg-homeinfo-container {
  padding: 0 !important;
  margin: auto !important;
}

.stg-homeinfo-column {
  // padding-top: 0 !important;
  margin: auto;
  padding: 10px 0;
}

.stg-homeinfo-row {
  margin: auto;
}

@media #{$media-md} {
  .stg-homeinfo {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem !important;

    &-link {
      padding-left: 20px;
    }

    &-texts {
      padding: 0 !important;
      width: 90%;
    }

    &-title {
      &-info {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 1rem;
        line-height: 1.4;
      }
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }

    &-description {
      max-width: 90%;
      padding-left: 20px;
    }

    &-image {
      padding: 1rem 1rem !important;
      width: 80%;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

.dots {
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  // bottom: -30px;
  left: 50%;
  z-index: 1;
}

@media #{$media-md} {
}
