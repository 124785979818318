.horizontal-driver {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: $primary-100;
  }

  .dots {
    display: flex;
    flex-direction: row;
    column-gap: 18px;

    .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      &.dot-one {
        background-color: $primary-300;
      }
      &.dot-two {
        background-color: $primary-500;
      }
      &.dot-three {
        background-color: $primary-400;
      }
    }
  }
}
