// * {
//   padding: 0 !important;
//   margin: 0 !important;
// }
body {
  padding: 0 !important;
  overflow-x: hidden !important;
}
a:focus,
a:active {
  outline: none !important;
}
.stg-header {
  // text-align: left !important;
  margin-top: 1rem !important;
  display: flex;
  justify-content: center;
  direction: ltr !important;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: light-neutral(0);

  &-logo {
    width: 450px;
    height: 102px;
  }
  &-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ul {
      display: flex;
      list-style-type: none;
      align-items: center;
      align-content: center;
      gap: 2rem;
      font-size: 20px;
      color: light-neutral(400);
      padding: 0 !important;

      li {
        a,
        span {
          font: normal normal normal 20px/26px Roboto;
        }
      }
    }
    &-button {
      border: none;
      outline: none;
      background-color: white;
      color: $primary-300;
    }
  }
}
.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: $primary-300;

  //   top: 0 !important;
  //   background-color: dark;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  padding: 2rem 0;
  transition: all, 0.2s ease-in-out;
  font-size: 18px;
  left: -200% !important;

  ul {
    list-style-type: none !important;
    display: flex;
    flex-direction: column;
    line-height: 2rem;
    font-size: 20px;
    padding: 0 25px !important;
    li {
      border-bottom: 1px solid rgba(192, 119, 119, 0.755);
      line-height: 3.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
    }
  }

  &-show {
    transition: all, 0.2s ease-in-out;
    left: 0 !important;
  }
}

.stg-mobile-menu {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px !important;
  z-index: 999;
  &-logo {
    width: 80px;
  }
  &-button {
    background-color: black;
    color: #846361;
    border: none;
  }
  &-language {
    display: flex;
    margin-top: 25px !important;

    flex-direction: row;

    font-size: 19px;
    font-weight: 500;
    background-color: black;
    color: #846361;
    border: none;
  }
}
.stg-language {
  display: flex;
  justify-content: center;
}
.language-selection {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  min-height: 80px;
  z-index: 6;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem !important;
  color: light-neutral(400);
  left: -75%;

  &-button {
    position: relative;
    border: none;
    background-color: white;
    color: light-neutral(400);
    cursor: pointer;
  }
  &-show {
    opacity: 1;
    border: none;
    background-color: white;
  }
}
.language-selection:before {
  content: '';
  position: absolute;
  right: 36%;
  top: -15px;
  transform: rotate(90deg);
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid white;
  border-bottom: 13px solid transparent;
}
.stg-menu-language {
  cursor: pointer;
  width: 100%;
}

@media only screen and (max-width: 860px) {
  .stg-header {
    padding: 0 !important;
    display: flex;

    &-menu {
      justify-content: flex-end;
      &-items {
        color: $primary-300;
      }
    }
    &-logo {
      width: 250px;
    }
  }
}
@media #{$media-xl} {
  .stg-header {
    // display: flex;
    // flex-direction: row;
    // &-logo {
    //   width: 200px;
    // }
    &-menu {
      justify-content: flex-end;
      // ul {
      //   font-size: 16px;
      //   font-weight: bold;
      // }
    }
  }
}
@media #{$media-md} {
  // .stg-header {
  //   &-menu {
  //     &-logo {
  //       width: 200px;
  //     }
  //     ul {
  //       font-size: 16px;
  //       font-weight: bold;
  //     }
  //   }
  // }
}
