footer {
  margin-top: 134px;
  padding-bottom: 42px;

  .footer-row {
    justify-content: space-between;
    padding-top: 74px;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    margin-bottom: 58px;

    &-logo {
      .image {
        img {
          width: 151px;
          height: 133px;
        }
      }
    }

    .contacts-items {
      .contacts-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 32px;

        .text {
          color: $primary-700;
          font: normal normal normal 18px/24px Roboto;
        }

        .c-icon {
          color: $primary-500;
        }

        &.brand-name {
          margin-bottom: 39px;

          span {
            color: $primary-700;
            font: normal normal bold 24px/32px Roboto;
          }
        }

        &.adress {
          margin-bottom: 14px;
          .adress-text {
            display: flex;
            flex-direction: column;
          }
        }

        &.phone {
          margin-bottom: 30px;
        }

        &.mail {
          margin-bottom: 30px;
        }
      }
    }
  }

  .contact-map {
    display: flex;
    flex-direction: column;
    gap: 30px;

    iframe {
      border: none;
      min-height: 265px;
      filter: grayscale(100%) invert(94%) contrast(94%);
    }

    .text {
      color: $primary-700;
      font: normal normal normal 18px/24px Roboto;
    }
  }

  .copyright-layout {
    margin-top: 64px;

    &-text {
      color: $primary-700;
      font: normal normal 600 18px/24px Roboto;
    }
  }

  .contact-form-layout {
    position: relative;

    .form-title {
      display: block;
      color: $primary-300;
      font: normal normal bold 30px/39px Roboto;
      margin-bottom: 32px;
    }

    .contact-form {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      max-width: 536px;

      .form-input {
        input,
        textarea {
          border: 1px solid $primary-300;
          border-radius: 5px;
          padding: 12px;
          width: calc(100% - 24px);

          &:focus {
            outline: 1px solid $primary-600;
          }
        }
      }

      .form-textarea {
        textarea {
          height: 308px;
        }
      }

      .send-button {
        position: absolute;
        right: 0;
        bottom: -10px;
        background-color: $primary-300;
        color: #fff;
        font: normal normal medium 20px/26px Roboto;
        border-radius: 5px;
        padding-left: 28px;
        padding-right: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        .button-text {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .last-message {
      position: absolute;
      bottom: 0;
      opacity: 0;
      background-color: primary(500);
      transition: all, 0.4s;
      padding: 15px;
      border-radius: 5px;
      box-sizing: border-box;
      display: block;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      z-index: 1;
      &--active {
        bottom: -100px;
        opacity: 1;
        color: light-neutral(0);
        pointer-events: auto;
      }
      &--sent {
        background-color: green;
      }
      &--error {
        background-color: red;
      }
    }
  }
}

@media #{$media-md} {
  footer {
    .footer-row {
      gap: 94px;
      padding-top: 0;
    }

    .contact-form-layout {
      .contact-form {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        .form-textarea {
          textarea {
            height: 184px;
          }
        }

        .send-button {
          width: fit-content;
          position: relative;
          padding-left: 34px;
          padding-right: 34px;
          margin: auto;
        }
      }
      .last-message {
        &--active {
          bottom: -70px;
        }
      }
    }

    .contacts {
      flex-direction: column;
      row-gap: 28px;

      &-logo {
        text-align: center;
        .image {
          img {
            width: 71px;
            height: 81px;
          }
        }
      }

      .contacts-items {
        .contacts-item {
          flex-direction: column;
          row-gap: 12px;

          .text {
            font: normal normal normal 14px/19px Roboto;
            text-align: center;
          }

          &.brand-name {
            margin-bottom: 28px;

            span {
              font: normal normal bold 18px/24px Roboto;
            }
          }
        }
      }
    }

    .contact-map {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      iframe {
        width: 100%;
        min-height: 222px;
      }

      .text {
        font: normal normal normal 14px/19px Roboto;
      }
    }

    .copyright-layout {
      margin-top: 44px;

      &-text {
        display: block;
        text-align: center;
        font: normal normal medium 14px/19px Roboto;
      }
    }
  }
}

@media #{$media-xl} {
  footer {
    .contacts {
      column-gap: 44px;
      &-logo {
        .image {
          img {
            width: 111px;
            height: 113px;
          }
        }
      }
    }
  }
}
