.stg-detail {
  &-titles {
    color: $primary-300;
    .title {
      font: normal normal bold 60px/73px Montserrat;
      width: 90%;
      overflow: hidden;
      word-break: break-word;
      line-height: 1.1;
    }
    .subtitle {
      font: normal normal normal 30px/39px Roboto;
      word-break: break-word;
      line-height: 1.3;
    }
  }
  &-parent {
    .left-part {
      &-info {
        margin-bottom: 15px;
      }
      span {
        font: normal normal normal 18px/24px Roboto;
      }
      div {
        font: normal normal bold 18px/24px Roboto;
        word-break: break-word;
      }
    }
    .right-part {
      font: normal normal medium 16px/22px Roboto;
    }
  }
}
.detailBanner {
  width: 100% !important;
  height: 400px;
  margin-bottom: 1rem;
}
.detailBanner img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  min-height: 400px !important;
}
.container--homepage {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media #{$media-sm} {
  .stg-detail {
    &-titles {
      .title {
        font-size: 21px;
      }
      .subtitle {
        font-size: 17px;
      }
    }
    &-parent {
      .left-part {
        span {
          font-size: 14px;
          padding-top: 10px;
        }
        div {
          font-weight: 600;
          font-size: 14px;
        }
      }
      .right-part {
        word-break: break-word;
      }
    }
  }
  .detailBanner {
  }
  .detailBanner img {
  }
  .container--homepage {
  }
}
