.stg-ourservices-container {
  padding: 0 !important;
  margin: auto !important;
  // width: 100%;
}
.stg-ourservices-column {
  padding: 10px 0 !important;
}
.stg-ourservices-row {
  margin: 0 !important;
}

.stg-ourservices {
  width: 100% !important;
  &:first-child {
    padding-top: 54px;
  }

  &-page-title {
    color: $primary-700;
    font-weight: 500;
    font: normal normal normal 80px/110px DM Serif Display;
    margin: calc(240px - 54px) 0 68px 0 !important;

    &.ourservices-home-title {
      color: $primary-300;
      margin: 0 0 68px 0 !important;
    }
  }

  &-card {
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    gap: 15px;

    &-item {
      position: relative;
      overflow: hidden;
      max-height: 400px;
      min-height: 400px;

      &-image {
        width: 100%;
        position: relative;
        min-height: 400px;
        max-height: 400px;

        &::after {
          content: '';
          background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/HizmetlerimizAfter.png);
          width: 63px;
          height: 63px;
          position: absolute;
          background-repeat: no-repeat;
          top: 3%;
          right: 10px;
        }
      }

      &-texts {
        color: light-neutral(0);
        padding-left: 5%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 15px;
        gap: 10px;
        background: transparent
          linear-gradient(
            180deg,
            #00000000 0%,
            var(--unnamed-color-000000) 100%
          )
          0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;

        &-title {
          font: normal normal bold 24px/32px Roboto;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 95%;
        }

        &-desc {
          font: normal normal medium 14px/21px Roboto;
          letter-spacing: 0px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 2;
          word-break: break-word;
          -webkit-box-orient: vertical;
          width: 90%;
        }
      }
    }
  }
}

@media #{$media-xl} {
  .stg-ourservices {
    &-card {
      grid-template-columns: auto auto;
    }
  }
}

@media #{$media-lg} {
  .stg-ourservices {
    &-card {
      grid-template-columns: auto auto;
    }
  }
}

@media #{$media-md} {
  .stg-ourservices {
    &:first-child {
      padding-top: 0;
    }
    &-page-title {
      font-size: 30px;
      padding: 5rem 0 3rem 1rem;
      margin: 0 !important;
      &.ourservices-home-title {
        color: $primary-300;
        margin: 0 !important;
        padding: 0 0 0 10px;
      }
    }

    &-card {
      display: grid;
      grid-template-columns: auto auto;
      padding: 10px;

      &-item {
        width: 100% !important;
        align-items: center;

        &-image {
          width: 350px;
        }

        &-texts {
          &-projectname {
            font-size: 16px;
          }
          &-location {
            font-size: 16px;
          }
        }
      }
    }

    &-link {
      font-size: 18px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media #{$media-sm} {
  .stg-ourservices {
    &-card {
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
    }
  }
}
