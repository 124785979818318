.stg-detail-slider-container {
  margin: auto !important;
  padding: 0 !important;
}

.stg-detail-slider-row {
  margin: 0;
}

.stg-detail-slider-column {
  padding: 0 !important;
  padding-top: 10px !important;
}

.StgDetailSlider-slider {
  position: relative;
  background-color: white;
  border: none;
}

.stg-detail-slider {
  position: relative;

  &-content-item {
    padding: 20px;

    &::after {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
      width: 47px;
      height: 47px;
      position: absolute;
      background-repeat: no-repeat;
      right: 0;
      z-index: 55;
      top: 0;
    }
    &::before {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
      width: 47px;
      height: 47px;
      position: absolute;
      background-repeat: no-repeat;
      left: 0;
      bottom: 0;
    }
    figure {
      width: 100%;
      .image {
        width: 100%;

        img {
          min-height: 750px;
          max-height: 750px;
          width: 100%;
          object-fit: fill;
        }
      }
    }
  }
}

.button-layout {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 65px;
  overflow: hidden;

  &-prev {
    right: 13%;
    bottom: 10%;
  }

  &-next {
    right: 7%;
    bottom: 10%;
  }

  &.is-mobile {
    // ....mobile css
    width: 60px;
    height: 60px;

    &.button-layout-prev {
      right: 20%;
      bottom: 7%;
    }

    &.button-layout-next {
      right: 7%;
      bottom: 7%;
    }
  }

  .arrow-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: $primary-300;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
}

.StgDetailSlider-slider {
  padding: 0 !important;
}

.slick-counter {
  &-number {
    font: normal normal bold 18px/24px Roboto;
  }
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-300;
  &-prev {
    border: none;
    background-color: transparent;
    color: $primary-300;
    cursor: pointer;
  }
  &-next {
    border: none;
    background-color: transparent;
    color: $primary-300;
    cursor: pointer;
  }
}
.StgDetailSlider-content-arrow-prev {
  left: -5%;
  bottom: 45%;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $primary-300;
}

.StgDetailSlider-content-arrow-next {
  right: -5%;
  bottom: 45%;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $primary-300;
}

@media #{$media-sm} {
  .StgDetailSlider-content-arrow-prev {
    right: 20%;
    bottom: 3%;
    display: none;
  }

  .StgDetailSlider-content-arrow-next {
    right: 10%;
    bottom: 3%;
    display: none;

    span {
      font-size: 25px;
    }
  }
  .stg-detail-slider {
    position: relative;
    padding: 20px;

    &-content-item {
      figure {
        .image {
          img {
            min-height: 350px;
            max-height: 350px;
            object-fit: cover;
          }
        }
      }
    }
  }
  .button-layout {
    &-prev {
      right: 13%;
      bottom: 10%;
    }

    &-next {
      right: 7%;
      bottom: 10%;
    }

    &.is-mobile {
      height: 40px;
      &.button-layout-prev {
        right: 25%;
        bottom: 7%;
      }

      &.button-layout-next {
        right: 5%;
        bottom: 7%;
      }
    }

    .arrow-button {
      cursor: auto;
    }
  }
}
