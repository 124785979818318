.stg-slider-container {
  margin: auto !important;
  padding: 0 !important;
}

.stg-slider-row {
  margin: 0;
}

.stg-slider-column {
  padding: 0 !important;
  // padding-top: 10px !important;
}

.StgSlider-slider {
  position: relative;
  background-color: white;
  border: none;
}

.stg-slider {
  position: relative;
  object-fit: fill;

  &-content-item {
    figure {
      width: 100%;

      .image {
        width: 100%;
        &::after {
          content: '';
          background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
          width: 70px;
          height: 70px;
          position: absolute;
          background-repeat: no-repeat;
          right: 2%;
          top: 10%;
        }
        &::before {
          content: '';
          background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
          width: 70px;
          height: 70px;
          position: absolute;
          background-repeat: no-repeat;
          left: 8%;
          bottom: 15%;
        }

        img {
          height: calc(100vh - (102px + 1rem));
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &-slogan {
    position: absolute;
    z-index: 999;
    color: $primary-100;
    bottom: 17%;
    left: 10%;
    // transform: translate(-50%, -50%);
    font: normal normal normal 80px/110px DM Serif Display;
  }
  &-dots {
    position: relative;
    bottom: 30px;
  }
}

.StgSlider-content-arrow {
  svg {
    path {
      cursor: pointer;
    }
  }
  outline: none;
  border: none;
  position: absolute;
  z-index: 9;
  border: none;
  outline: none;
  background-color: transparent;
  color: $primary-100;
  // cursor: pointer;

  &-prev {
    outline: none;
    border: none;
    right: 15%;
    bottom: 10%;

    .c-icon {
      transform: rotate(90deg);
    }
  }

  &-next {
    right: 11.5%;
    bottom: 10%;

    .c-icon {
      transform: rotate(-90deg);
    }
  }

  .c-icon {
    // font-size: 174px;

    &.is-mobile {
      font-size: 94px;
    }
  }
}

.StgSlider-slider {
  padding: 0 !important;
}

@media #{$media-sm} {
  .StgSlider-content-arrow-prev {
    svg {
      path {
        cursor: auto;
      }
    }
    right: 20%;
    bottom: 3%;

    span {
      font-size: 25px;
    }
  }

  .StgSlider-content-arrow-next {
    svg {
      path {
        cursor: auto;
      }
    }
    right: 7%;
    bottom: 3%;

    span {
      font-size: 25px;
    }
  }

  .stg-slider {
    &-content-item {
      figure {
        .image {
          &::after {
            right: 0;
            top: 1%;
          }
          &::before {
            left: 8%;
            bottom: 12%;
          }
        }
      }
    }
    &-slogan {
      font-size: 30px;
      bottom: 13%;
      left: 15%;
    }
  }
}
