.stg-aboutus {
  display: flex;
  width: 100% !important;
  justify-content: space-around;
  align-items: center;
  color: $primary-700;

  &-main {
    padding-top: 54px;
    display: flex;
    flex-direction: row;
    margin: calc(240px - 54px) 0 68px 0 !important;
  }
  &-texts {
    width: 50%;
    display: flex;
    flex-direction: column;
    // padding: 6rem 6rem !important;
    gap: 30px;
  }
  &-title {
    font: normal normal normal 80px/110px DM Serif Display;
  }
  &-slogan-parent {
    padding-left: 2rem;
    width: max-content;

    .slogan {
      position: relative;
      max-width: 55%;
      font: normal normal normal 22px/24px DM Serif Display;
      &::after {
        content: '';
        background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/about-us-after.png);
        width: 23px;
        height: 23px;
        position: absolute;
        background-repeat: no-repeat;
        right: 0;
        bottom: 0;
      }
      &::before {
        content: '';
        background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/about-us-before.png);
        width: 23px;
        height: 23px;
        position: absolute;
        background-repeat: no-repeat;
        left: -20px;
        top: -10px;
      }
    }
  }
  &-description {
    font: normal normal normal 20px/24px Montserrat;
    letter-spacing: 0px;
    color: dark-neutral(0);

    max-width: 85%;
  }
  &-image {
    position: relative;
    padding: 20px;
    height: fit-content;

    &::after {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
      width: 47px;
      height: 47px;
      position: absolute;
      background-repeat: no-repeat;
      right: 0;
      top: 0;
    }
    &::before {
      content: '';
      background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
      width: 47px;
      height: 47px;
      position: absolute;
      background-repeat: no-repeat;
      left: 0;
      bottom: 0;
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }
}
.stg-aboutus-container {
  padding: 0 !important;
  margin: auto !important;
}
.stg-aboutus-column {
  margin: 0;
  padding: 0;
}
.stg-aboutus-row {
  margin: 0;
}
@media #{$media-md} {
  .stg-aboutus {
    &-image {
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }
    &-main {
      display: flex;
      flex-direction: column;
    }
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem !important;
    &-texts {
      padding: 0 !important;
      width: 90%;
    }
    &-title {
      font-size: 30px;
      padding-left: 20px;
      padding-top: 1rem;
    }
    &-slogan-parent {
      .slogan {
        max-width: 90%;
      }
      max-width: 90%;
      padding-left: 20px;
    }
    &-description {
      max-width: 90%;
      padding-left: 20px;
    }
    &-image {
      padding: 1rem 1rem !important;
      width: 80%;
    }
  }
}

@media #{$media-sm} {
  .stg-aboutus {
  }
}
