.stg-ourteam-container {
  padding: 0 !important;
  margin: auto !important;
  // width: 100%;
}

.stg-ourteam-row {
  margin: 0;
}

.stg-ourteam-column {
  padding: 0 !important;
  // padding-top: 10px !important;
}

.stg-ourteam {
  position: relative;
  width: 100% !important;

  &:first-child {
    padding-top: 54px;
  }

  &-page-title {
    color: $primary-700;
    font: normal normal normal 80px/110px DM Serif Display;
    margin: calc(240px - 54px) 0 68px 0 !important;

    &.ourteam-home-title {
      color: $primary-300;
      margin: 0 0 68px 0 !important;
    }
  }

  &-card {
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    gap: 15px;

    &-item {
      position: relative;
      overflow: hidden;
      min-height: 400px;
      max-height: 400px;

      &-image {
        width: 100%;
        position: relative;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 300ms ease-in-out;
        img {
          min-width: 536px;
          max-width: 536px;
          min-height: 400px;
          max-height: 400px;
          object-fit: cover;
        }

        &:hover {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }

      &-texts {
        color: light-neutral(0);
        padding: 15px;
        padding-left: 30px;
        position: absolute;
        bottom: 0;
        display: flex;
        width: 90%;
        flex-direction: column;
        background: transparent
          linear-gradient(
            180deg,
            #00000000 0%,
            var(--unnamed-color-000000) 100%
          )
          0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;

        &-name {
          font: normal normal bold 20px/26px Roboto;
          width: 80%;
          overflow: hidden;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        &-status {
          font: normal normal medium 20px/26px Roboto;
          width: 80%;
          overflow: hidden;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  &-link {
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 30px;
    font-weight: 500;
    border: 1px solid $primary-300;
    margin-top: 10px;
    color: light-neutral(0);
    background-color: $primary-300;
    padding: 10px;
    width: 20%;
    justify-content: space-between;
  }

  &.homepage-section {
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 60%;
      background-color: $primary-400;
      z-index: -1;
    }
  }
}

.link {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7rem;
}

@media #{$media-lg} {
  .stg-ourteam {
    &-card {
      grid-template-columns: auto auto;
    }
  }
}

@media #{$media-md} {
  .stg-ourteam {
    &-page-title {
      font-size: 30px;
      padding: 5rem 0 3rem 1rem;
      margin: 0 !important;
    }

    &-card {
      display: grid;
      grid-template-columns: auto auto;
      padding: 10px;

      &-item {
        width: 100% !important;
        align-items: center;

        &-image {
          width: 350px;
        }

        &-texts {
          &-name {
            font-size: 16px;
          }
          &-status {
            font-size: 16px;
          }
        }
      }
    }

    &-link {
      font-size: 18px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media #{$media-sm} {
  .stg-ourteam {
    &-card {
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
    }
  }
  .link {
    margin: 0px 1rem;
  }
}
