.stg-ourprojects-container {
  padding: 0 !important;
  margin: auto !important;
  // width: 100%;
}

.stg-ourprojects-row {
  margin: auto;
}

.stg-ourprojects-column {
  padding: 0 !important;
  // padding-top: 10px !important;
}

.stg-ourprojects {
  width: 100% !important;
  &:first-child {
    padding-top: 54px;
  }

  &-page-title {
    font: normal normal normal 80px/110px DM Serif Display;
    color: $primary-700;
    margin: calc(240px - 54px) 0 68px 0 !important;

    &.ourprojects-home-title {
      color: $primary-300;
      margin: 0 0 68px 0 !important;
    }
  }

  &-card {
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    gap: 15px;

    &-item {
      position: relative;
      overflow: hidden;
      min-height: 400px;
      max-height: 400px;

      &-image {
        width: 100%;
        position: relative;

        img {
          min-height: 400px;
          max-height: 400px;
          min-width: 536px;
          max-width: 536px;
          object-fit: cover;
        }
        &::after {
          content: '';
          background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderAfter.png);
          width: 50px;
          height: 50px;
          position: absolute;
          background-repeat: no-repeat;
          right: 2%;
          top: 5%;
          z-index: 1;
        }
        &::before {
          content: '';
          background-image: url(https://minio.wapbukafamedya.com/storage/Root/stg-art-design/SliderBefore.png);
          width: 50px;
          height: 50px;
          position: absolute;
          background-repeat: no-repeat;
          left: 3%;
          bottom: 22%;
          z-index: 1;
        }
      }

      &-texts {
        color: light-neutral(0);
        padding: 15px;
        padding-left: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        text-shadow: 2px 2px 23px rgba(0, 0, 0, 1);

        background: transparent
          linear-gradient(
            180deg,
            #00000000 0%,
            var(--unnamed-color-000000) 100%
          )
          0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat
          padding-box;

        &-projectname {
          font: normal normal bold 20px/26px Roboto;
          width: 80%;
          overflow: hidden;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        &-location {
          font: normal normal medium 20px/26px Roboto;
          display: -webkit-box;
          width: 80%;
          overflow: hidden;
          -webkit-line-clamp: 1;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  &-link {
    display: flex;
    align-items: center;
    gap: 50px;
    font-size: 30px;
    font-weight: 500;
    border: 1px solid $primary-300;
    margin-top: 10px;
    color: light-neutral(0);
    background-color: $primary-300;
    padding: 10px;
    width: 20%;
    justify-content: space-between;
    font: normal normal medium 30px/39px Roboto;
  }
}

@media #{$media-md} {
  .stg-ourprojects {
    &:first-child {
      padding-top: 0;
    }
    &-page-title {
      font-size: 30px;
      padding: 5rem 0 3rem 1rem;
      margin: 0 !important;
      &.ourprojects-home-title {
        color: $primary-300;
        margin: 0 !important;
        padding: 0 0 0 10px;
      }
    }

    &-card {
      display: grid;
      grid-template-columns: auto auto;
      padding: 10px;

      &-item {
        width: 100% !important;
        align-items: center;

        &-image {
          width: 350px;
          &::after {
            top: 5%;
          }
          &::before {
            bottom: 30%;
          }
        }

        &-texts {
          &-projectname {
            font-size: 16px;
          }
          &-location {
            font-size: 16px;
          }
        }
      }
    }

    &-link {
      font-size: 18px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media #{$media-sm} {
  .stg-ourprojects {
    &-texts {
      &-projectname {
        font-size: 14px;
      }
      &-location {
        font-size: 16px;
      }
    }
    &-card {
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
    }
  }
}
